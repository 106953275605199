// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

var bootstrap = require('bootstrap')
global.bootstrap = bootstrap
window.bootstrap = bootstrap

import Chart from 'chart.js/auto'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", function(){
  setTimeout(initialize_syntax_highlighting, 0);
})

function initialize_syntax_highlighting(){
  document.querySelectorAll('pre code').forEach((block) => {
    HighlightJS.configure({tabReplace: '  '})
    HighlightJS.highlightBlock(block);
  });
}


const render_main_chart = () => {
  const chart = document.getElementById('myChart')
  if (!chart) {
    return
  }
  const dataset = JSON.parse(chart.dataset.dataset)
  const context = chart.getContext('2d')
  const myChart = new Chart(context, {
    type: 'bar',
    options: {
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: false,
          beginAtZero: true
        }
      }
    },
    data: {
      labels: dataset.map(({ date }) => date),
      datasets: [
        {
          label: 'Paid API Calls',
          data: dataset.map(({ billable_count }) => billable_count),
          backgroundColor: 'rgba(6, 153, 151)',
        },
        {
          label: 'Total API Calls',
          data: dataset.map(({ total_count }) => total_count),
          backgroundColor: 'rgba(230, 230, 230)',
        }
      ]
    }
  })
}

const render_by = ({ chart_selector, by_attribute, label_modifier = x => x }) => {
  const chart = document.getElementById(chart_selector)
  if (!chart) {
    return
  }
  const dataset = JSON.parse(chart.dataset.dataset)
  const dates = dataset[0]["by_day"].map(({ date }) => date).sort()
  const context = chart.getContext('2d')
  const myChart = new Chart(context, {
    type: 'bar',
    options: {
      responsive: true,
      parsing: {
        xAxisKey: 'date',
        yAxisKey: 'billable_count'
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          beginAtZero: true
        }
      }
    },
    data: {
      labels: dates,
      datasets: dataset.map((value, index) => {
        const { by_day: counts } = value
        const label = label_modifier(value[by_attribute])
        return ({
          label,
          data: counts,
          backgroundColor: `rgb(${(index+1) * 50 % 255}, ${(index+1) * 150 % 255}, ${(index+1) * 200 % 255})`
        })
      })
    }
  })
}
const render_by_session_type_chart = () => render_by({ chart_selector: "myChartByType", by_attribute: "session_type" })
const render_by_api_key_chart = (api_keys) => render_by(
  {
    chart_selector: "myChartByApiKey",
    by_attribute: "api_key",
    label_modifier: (key) => {
      let name = api_keys.find(({ api_key }) => api_key == key)
      name = !!name ? name.name : key
      return name
    }
  }
)

const fetch_api_keys = (callback) => {
  fetch(window.heimdall_server_url+'api_keys.json', {
    method: 'GET',
    credentials: 'include'
  })
  .then(function(response) {
    return response.json();
  }).then(callback)
  .catch(function(){})
}

const render_charts = () => {
  render_main_chart()
  render_by_session_type_chart()
  fetch_api_keys(render_by_api_key_chart)
}

const replace_api_key_template_with_default = () => {
  fetch(window.heimdall_server_url+'api_keys.json', {
    method: 'GET',
    credentials: 'include'
  }).then(function(response) {
    return response.json()
  }).then(function(all_keys){
    return {
      default_transit_test_key: all_keys.find(key => key.is_default_transit_test_key).api_key,
      default_ticketing_test_key: all_keys.find(key => key.is_default_ticketing_test_key).api_key
    }
  }).then(function(default_keys){
    $('.api-key-placeholder').html((index, text) => text.replace('---LOGIN_OR_REGISTER_FOR_FREE_TO_INSERT_TRANSIT_API_KEY_HERE---', default_keys.default_transit_test_key).replace('---LOGIN_OR_REGISTER_FOR_FREE_TO_INSERT_TICKETING_API_KEY_HERE---', default_keys.default_ticketing_test_key))

    let demo_preview = document.getElementById('demo-preview')
    if (demo_preview) {
      add_api_key_to_iframe(default_api_key, demo_preview, 0)
    }
  })
}

const add_api_key_to_iframe = function(api_key, demo_preview, counter) {
  if (demo_preview.contentWindow.location == "about:blank") {
    setTimeout(() => add_api_key_to_iframe(api_key, demo_preview, counter + 1), 100 * counter)
  } else {
    const demoPreviewParams = new URLSearchParams(demo_preview.contentWindow.location.search)
    demoPreviewParams.set('api_key', api_key)
    demo_preview.contentWindow.location.search = demoPreviewParams.toString()
  }
}

$(render_charts)
$(document).on('turbolinks:load', render_charts)
$(replace_api_key_template_with_default)
$(document).on('turbolinks:load', replace_api_key_template_with_default)
